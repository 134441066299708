.Menu {
  font-family: Questrial, sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  padding-top: 3vh;
  /* border-top: #000 1px solid; */
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.Menu-Row {
  display: flex;
  justify-content: right;
  max-width: 600px;
  margin: 0 auto;
  height: fit-content;
}

.Wallet {
  padding-right: 16px;
}

.Navbar {
  font-family: Questrial, sans-serif;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  /* border-top: #000 1px solid; */
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.Navbar-Row {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  max-width: 600px;
  margin: 0 auto;
}

.Navbar-Item:not(.selected) {
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
}

.Navbar-Item.selected {
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #d8fd00;
}

.Navbar-Text {
  font-size: 12px;
  font-weight: 300;
  margin-top: 4px;
}

.Links-Row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.Link-Container {
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Link {
  background-color: #d8fd00;
  padding: 12px;
  border-radius: 100%;
  max-width: fit-content;
}

.btn.wallet {
  font-family: Questrial, sans-serif; 
  font-size: 14px;
  font-weight: 600; 
  text-transform: uppercase;
  border: none;
  border-radius: 0px;
  min-height: 40px;
  min-width: 100px !important;
}