@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

a {
  color: #000;
  text-decoration: none;
}

h1, h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 36px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  text-align: center;
  font-family: Questrial;
  background-color: #d8fd00;
  color: black;
  font-size: 14px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Content {
  min-height: 100vh;
  max-width: 600px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px 16px 76px 16px;
  display: flex;
  align-items: center;
}

.Product, .Product-Detail-Container, .Token-Container, .Title-Container, .Attributes-Container, .Attribute {
  display: flex;
  flex-direction: column;
}

.Product {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  gap: 20px;
}

.Product-Image {
  width: 100%;
  pointer-events: none;
  z-index: 0;
}

.Product-Detail-Container {
  text-align: left;
  justify-content: center;
  gap: 1.5rem;
}

.Token-Container {
  justify-content: center;
  align-items: center;
}

.Token {
  background-color: #000;
  color: #fff;
  font-weight: 600;
  padding: 8px 12px;
  max-width: fit-content;
  text-transform: uppercase;
}

.Attributes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.Attributes-Container {
  gap: 4px
}

.Attribute {
  gap: 4px;
  border: #000 1px solid;
  border-bottom: #000 4px solid;
  padding: 10px;
  text-align: left;
  font-family: Poppins, sans-serif;
}

.Attribute-Title {
  font-weight: bold;
  text-transform: uppercase;
}

.App-link {
  color: #61dafb;
}

.Description {
  font-size: 14px;
}

.popup, .popup-inner, .popup-header, .popup-container, .popup-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 16px;
}

.popup-inner {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 16px;
}

.popup-tab {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
}

.popup-container {
  gap: 16px;
  padding-bottom: 16px;
}

.popup-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  text-transform: uppercase;
}

.popup-image {
  width: 100%;
}

.close-icon {
  color: #000;
  background-color: #fff;
  border: none;
  font-size: 24px;
}

.btn, .btn.btn-primary.claim {
  color: #000; 
  font-family: Questrial, sans-serif; 
  background-color: #d8fd00;
  font-weight: 800;
  text-transform: uppercase;
  border-radius: 0px;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  min-height: 45px !important;
}

.btn.btn-primary.claim:hover {
  background-color: #d8fd0095;
}

.btn.btn-primary.claim:disabled {
  background-color: #666;
  color: #fff;
  opacity: 0.5;
}

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.Alert-Error{
  color: #ff3333;
  font-size: 14px;
  background-color: #fbbaba;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Alert-Loading{
  color: #40a6ce;
  font-size: 14px;
  background-color: #40a6ce20;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 20px;
}